@import "variables";

.large-wrapper .wrapper {
    padding-top: 0;
    padding-bottom: 0;
    &::after {
        content: none; }
    @media  (min-width: $bp-sm) {
        padding-bottom: 1rem;
        &::after {
            content: ''; } }
    @media (min-width: $bp-lg) {
        padding-top: 1.5rem; } }

.large-wrapper .container {
    padding-right: 0;
    padding-left: 0;
    // pour éviter scroll-vertical
    overflow: hidden;
    background: $white;
    .row .white-container {
        box-shadow: none;
        padding: 1rem; }
    .row .transparent-container {
        box-shadow: none;
        padding: 1rem; }
    @media  (min-width: $bp-sm) {
        background: 0;
        .row .white-container {
            box-shadow: 0 3px 6px rgba(0,0,0,0.16); } }
    @media (min-width: $bp-xl) {
        max-width: 1170px; } }


.menu-bar {
    background: $darker-grey;
    display: flex;
    color: $white;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    box-shadow: 0 5px 10px -5px rgba(0,0,0,0.5);
    a {
      color: $white; }

    .logo {
        margin-top: 5px;
        width: 160px; }

    .menu {
        text-align: center;
        font-size: 10px;
        text-transform: uppercase;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        padding: .5rem 0 0 0;

        li {
            flex-grow: 1;
            padding: 0 .5rem;

            a {
                display: block;
                border-bottom: 5px solid transparent;
                padding-bottom: .5rem;
                &:hover,
                &:focus {
                    text-decoration: none;
                    border-bottom-color: $dark-red; }
                &.current {
                    border-bottom-color: $dark-red;
                    position: relative;
                    &::before {
                        content: '';
                        display: block;
                        border: 5px solid transparent;
                        border-top-color: $dark-red;
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        transform: translateX(-50%) translateY(5px); } } } }


        [class^="icon-"] {
            display: inline-block;
            font-size: 26px;
            height: 32px;
            vertical-align: middle; }

        .icon-inbox {
            font-size: 20px;
            line-height: 28px;
            position: relative;
            font-weight: bold;
            &[data-notif]::after {
                content: attr(data-notif);
                display: block;
                width: 20px;
                height: 20px;
                text-align: center;
                line-height: 1.3;
                font-size: 1rem;
                font-family: "Nissan Bold";
                background: $dark-red;
                color: $white;
                border-radius: 50%;
                position: absolute;
                top: 0;
                left: 0;
                transform: translate(-60%,-20%); } }



        @media (min-width: $bp-lg) {
            width: 65%;
            font-size: 12px;
            padding-top: 1rem;

            li {
                flex-grow: 0;

                &.inbox-menu {
                    padding-left: 1.5rem; }

                a {
                    padding-right: .5rem;
                    &.current {
                        font-family: $font-bold; } }

                br {
                    display: none; } }

            [class^="icon-"] {
                margin-right: 1rem; } } }

    @media (min-width: $bp-md) {
        padding: 0 1rem; } }

.status-container {
    border-top: 5px solid $dark-grey;
    overflow: hidden;
    .toggle-menu {
        border-right: 1px solid $grey-border;
        padding-right: 2rem;
        position: relative;
        cursor: pointer;
        &::after {
            color: $dark-red;
            content: '\e91f';
            font-family: 'icomoon-nissan-you-plus';
            position: absolute;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
            right: 1rem; } }
    .icon-win {
        float: left;
        margin-right: 1rem; }
    .settings,
    .update-info {
        display: none; } }

.status-bronze {
    border-color: $cup-bronze;
    .icon-win-text .text {
        background: $cup-bronze; } }

.parameter-link {
    position: absolute;
    top: 18px;
    right: 30px;
    font-size: 23px;
    &:hover {
        text-decoration: none;
        span {
            text-decoration: none; } } }
.status-container-after {
    display: none; }

.status-container-open {
    text-align: center;
    position: relative;
    .toggle-menu {
        border: 0;
        padding-right: 0;
        &::after {
            top: 1rem;
            transform: translateY(-50%) rotate(-90deg); } }
    .picture {
        display: none; }
    .icon-win-text {
        float: none;
        margin: 0;
        font-size: 84px;
        .text {
            margin: -14px 10px 0 10px; } }
    h3 {
        margin-top: -5px; }
    h4 {
        display: none; }
    .settings {
        display: inline-block;
        position: absolute;
        top: 1rem;
        left: 1rem;
        font-size: 28px;
        &:hover,
        &:focus {
            text-decoration: none;
            color: $black; } }
    .update-info {
        display: block; }
    & + .status-container-after {
        display: block;
        background: $light-grey; } }

.timeline-container {
    position: relative;
    min-height: 300px;
    &::before,
    &::after {
        content: '';
        display: block;
        width: 2px;
        background: $darker-grey;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 1.5rem; }
    &::after {
        background: repeating-linear-gradient(to bottom, $white, $white 5px, transparent 5px, transparent 10px);
        top: auto;
        height: 100px; }
    .event {
        display: flex;
        margin: 0 0 2rem 0; }
    .event-icon {
        display: inline-block;
        background: $darker-grey;
        color: $white;
        border: 4px solid $white;
        border-radius: 50%;
        font-size: 20px;
        flex-shrink: 0;
        padding: 0;
        position: relative;
        width: 48px;
        height: 48px;
        line-height: 42px;
        text-align: center;
        z-index: 1;
        .alert-icon {
            position: absolute;
            top: -5px;
            right: -5px;
            background: white;
            color: $dark-red;
            font-size: 14px;
            font-family: $font-bold;
            line-height: 14px;
            padding: 3px;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            text-align: center; }
        .icon-check {
            font-size: 12px;
            color: $green; } }
    .container-event {
        flex: 1;
        box-shadow: 0 2px 5px rgba(0,0,0,0.2);
        background: $white;
        border-radius: .5rem .5rem 0 0;
        margin-left: 1rem;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            right: 100%;
            top: 1rem;
            border: 10px solid transparent;
            border-right-color: $darker-grey; }
        header {
            background: $darker-grey;
            color: $white;
            border-radius: .5rem .5rem 0 0;
            padding:  1rem;
            position: relative;
            .alert-message {
                background: $white;
                font-family: $font-bold;
                color: $dark-red;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                padding: .3rem 0 .3rem 2rem;
                border-radius: .5rem 0 0 .5rem;
                border: 2px solid $dark-red;
                width: 120px;
                .icon {
                    background: $dark-red;
                    color: $white;
                    display: inline-block;
                    position: absolute;
                    left: .2rem;
                    width: 23px;
                    height: 23px;
                    text-align: center;
                    border-radius: 50%;
                    top: 50%;
                    transform: translateY(-50%); } } }

        .text {
            padding: 1.5rem 1rem;
            color: $grey; }
        footer {
            background: $lighter-grey;
            color: $dark-red;
            border-left: 7px solid $dark-red;
            padding-left: 1rem;
            padding-right: 1rem;
            .links-contact {
                a {
                    display: flex;
                    flex-grow: 1;
                    text-transform: uppercase;
                    font-family: $font-bold;
                    align-items: center;
                    justify-content: center;
                    padding-left: 10px;
                    .icon {
                        margin: 0 1rem 0 0;
                        font-size: 20px; }
                    &:first-child {
                        border-right: 1px solid $light-grey-border; }
                    &:hover,
                    &:focus {
                        text-decoration: none; } } }
            .links-contact-go {
                a {
                    display: flex;
                    flex-grow: 1;
                    text-transform: uppercase;
                    font-family: $font-bold;
                    justify-content: left;
                    padding-left: 10px;
                    padding-bottom: 1rem;
                    padding-top: 1rem;
                    border-bottom: 1px solid $light-grey-border;
                    .icon {
                        margin: 0 1rem 0 0;
                        font-size: 20px; }
                    &:hover,
                    &:focus {
                        text-decoration: none; } } }

            .links-contact-call {
                a {
                    display: flex;
                    flex-grow: 1;
                    text-transform: uppercase;
                    font-family: $font-bold;
                    justify-content: left;
                    padding-left: 10px;
                    padding-bottom: 1rem;
                    padding-top: 1rem;
                    .icon {
                        margin: 0 1rem 0 0;
                        font-size: 20px; }
                    &:hover,
                    &:focus {
                        text-decoration: none; } } } } }
    @media (min-width: $bp-md) {
        &::before,
        &::after {
            left: 50%;
            margin-left: -1px; }
        .event {
            margin-bottom: 0;
            &:nth-child(even) {
                margin-left: calc(50% - 24px); }
            &:nth-child(odd) {
                margin-right: calc(50% - 24px);
                flex-direction: row-reverse;
                .container-event {
                    margin-left: 0;
                    margin-right: 1rem;
                    &::before {
                        right: auto;
                        left: 100%;
                        border-right-color: transparent;
                        border-left-color: $darker-grey; } } } }
        > .tooltip-custom {
            &:nth-child(even) {
                margin-right: calc(50% + 24px + 1rem); }
            &:nth-child(odd) {
                margin-left: calc(50% + 24px + 1rem); } } }
    .container-event-warranty {
        &::before {
            border-right-color: $grey; }
        header {
            background: $grey; } }
    .event:nth-child(odd) .container-event-warranty {
        &::before {
            border-left-color: $grey; } } }

.message-important {
    background: $dark-red;
    color: $white;
    padding: 1rem;
    border-radius: .5rem;
    p {
        color: $white;
        margin-bottom: 1rem; }
    .button {
        background: $white; } }
