@import 'variables';

.dark-red {
  color: $dark-red !important; }

.dark-red-border {
  border: 1px solid $dark-red !important; }

.bg-dark-red {
  background-color: $dark-red !important; }

.darker-grey {
  color: $darker-grey !important; }

.bg-darker-grey {
  background-color: $darker-grey !important; }

.dark-grey {
  color: $dark-grey !important; }

.bg-dark-grey {
  background-color: $dark-grey !important; }

.grey-border {
  color: $grey-border !important; }

.light-grey-simple-border {
  border: 1px solid $light-grey !important; }

.light-grey-border {
  color: $light-grey-border !important; }

.light-grey {
  background: $light-grey !important; }

.lighter-grey-text {
  color: $light-grey-text; }

.red-text {
  color: $dark-red; }

.bronze {
  color: $cup-bronze !important; }

.bg-bronze {
  background-color: $cup-bronze !important; }

.silver {
  color: $cup-argent !important; }

.bg-silver {
  background-color: $cup-argent !important; }

.gold {
  color: $cup-or !important; }

.bg-gold {
  background-color: $cup-or !important; }

.platinum {
  color: $cup-platine !important; }

.bg-platinum {
  background-color: $cup-platine !important; }

.green {
  color: $green !important; }

.black {
  color: $black !important; }

.bg-black {
  background-color: $black !important; }

.white {
  color: $white !important; }

.bg-white {
  background-color: $white !important; }

.blue-active {
  color: $blue-active !important; }


/******************************/
/* REUSABLE */
/******************************/
.logo {
  width: 120px; }

.wrapper {
  background: url('../images/fond.jpg') top center / 100% no-repeat fixed $background;
  background-size: cover;
  padding: calc(60px + 2rem) 0 2rem 0;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &::after {
    content: '';
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    height: 250px;
    background: linear-gradient(to bottom, transparent 0%, $background 20%);
    z-index: 0; }
  main {
    position: relative;
    z-index: 2; }
  a {
    color: $dark-red; } }

.wrapper-with-nav {
    .wrapper {
        min-height: calc(100vh - 92px - 5rem - 20px);
        padding-top: 0;
        padding-bottom: 0; }
    .header {
        .container {
            height: auto; } } }

.button_modal {
  background: #C0092D !important;
  color: #FFF !important;
  outline: none;
  border: none !important;
  padding: 20px;
  font-weight: 300; }

.white-container {
  background-color: #FFF;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  margin-bottom: 8px;
  padding: 2rem;
  h1:first-child {
    margin-top: 0; } }

.button {
  border: 0;
  cursor: pointer;
  display: block;
  font: {
    family: $font-bold;
    size: 18px; }
  min-height: 45px;
  line-height: 40px;
  text: {
    decoration: none;
    align: center;
    transform: uppercase; }
  position: relative;
  padding-right: 3rem;
  padding-left: 2rem;
  span {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.2;
    padding: .5rem 0; }

  &::before {
    content: '\e91f';
    font-family: 'icomoon-nissan-you-plus';
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%); }

  &:active, &:focus, &:hover {
    text-decoration: none; }
  &.red {
    background: $dark-red !important;
    color: #FFF !important;
    outline: none;
    border: none !important; }

  &.white {
    background: #FFF;
    color: $darker-grey !important;
    border: 1px solid $grey-border; }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none; }

  &.with-icon {
    padding-right: .5rem;
    padding-left: .5rem;
    &::before {
      display: none; }
    .icon {
      position: absolute;
      left: .5rem;
      top: 50%;
      transform: translateY(-50%);
      font-size: 24px;
      padding: 0; }
    .icon-car-del,
    .icon-car-new {
      font-size: 18px; } }
  &.without-icon {
    padding-right: 1rem;
    padding-left: 1rem;
    &::before {
      display: none; } }
  &.back {
    padding-right: 2rem;
    padding-left: 3rem;
    &::before {
      transform: translateY(-50%) rotate(180deg);
      right: auto; }
    &.white {
      &::before {
        color: $darker-grey; } } }
  &.with-arrow {
    &::before {
      display: block; } }
  &.multiline-button {
    line-height: 16px;
    padding-top: 5px;
    font-size: 14px;
    &::after {
      content: '\E91C';
      font-family: icomoon-nissan-you-plus;
      color: #1EA50D;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      left: 1rem; } } }
.text-center {
  .button {
    margin: 0 auto; } }

.back-link {
  display: block;
  .icon {
    display: inline-block;
    font-size: 10px;
    transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    margin-right: 10px;
    position: relative;
    top: -1px; }
  strong {
    font-family: $font-bold; } }

.cursor-pointer {
  cursor: pointer; }

@media (min-width: $bp-xs) {
  .back-link {
    &.single-page {
      background-color: $dark-grey;
      color: #FFF !important;
      margin-bottom: 0 !important;
      padding: 10px 10px;
      position: relative;
      text: {
        align: left;
        transform: uppercase; }
      width: 100%; }
    .icon {
      position: static;
      left: 1rem;
      top: 11px;
      &:before {
        font-size: 1.1rem; } } } }

@media (min-width: $bp-lg) {
  .back-link {
    &.single-page {
      background-color: transparent;
      color: #000 !important;
      margin-bottom: 1.4rem !important;
      padding: 0;
      position: relative;
      text: {
        align: left;
        transform: none; } }
    .icon {
      position: static;
      left: 1rem;
      top: 11px;
      &:before {
        font-size: 0.8rem; } } }
  //.white-container
 }  //  padding: 3rem


.fs-20 {
  font-size: 20px !important; }

.fs-30 {
  font-size: 30px !important; }

.fs-40 {
  font-size: 40px; }

.fs-50 {
  font-size: 50px; }

.fs-13 {
  font-size: 13px; }

.fs-70 {
  font-size: 70px; }

.text-underline {
  text-decoration: underline; }

.tag {
  border-radius: 3px;
  color: $white;
  display: inline-block;
  font {
    family: $font-regular;
    size: 12px; }
  margin: 0 3px;
  padding: 1px 5px;
  position: relative;
  text: {
    align: center;
    transform: uppercase; } }


/******************************/
/* LAYOUT */
/******************************/

body {
  font-family: $font-regular;
  font-size: 16px;
  /*min-width: 380px*/ }

h1 {
  font-family: $font-bold;
  font-size: 24px;
  position: relative;
  margin-bottom: 1rem;
  &.with-border-top {
    padding-top: 15px;
    margin-bottom: 20px;
    &:after {
      content: "";
      border-bottom: 3px solid $dark-red;
      top: 0;
      left: 0;
      position: absolute;
      width: 53px; } }
  &.with-border-bottom {
    padding-bottom: 1rem;
    border-bottom: 1px dotted $light-grey-border; } }
.with-quote {
  border-bottom: 1px solid $light-grey-border;
  color: $grey-border!important;
  position: relative;
  padding: 0px 12px 0px 0px;
  &:hover,
  &:focus {
    text-decoration: none; }
  &:after {
    color: $grey-border;
    content: '\e91f';
    font-family: 'icomoon-nissan-you-plus';
    position: absolute;
    font-size: 11px;
    top: 3px;
    right: 0; } }
.required-fields {
  font-size: 14px; }
.justify-button {
  width: inherit; }

h2 {
  font-family: $font-bold;
  font-size: 20px;
  position: relative;
  margin-bottom: 1rem;
  &.with-border {
    padding-bottom: 15px;
    margin-bottom: 10px;
    &:after {
      content: "";
      border-bottom: 3px solid $dark-red;
      bottom: 0;
      left: 0;
      position: absolute;
      width: 53px; } }
  &.with-border-top {
    padding-top: 15px;
    margin-bottom: 20px;
    &:after {
      content: "";
      border-bottom: 3px solid $dark-red;
      top: 0;
      left: 0;
      position: absolute;
      width: 53px; }
    &.with-border-bottom {
      padding-bottom: 1rem;
      border-bottom: 1px dotted $light-grey-border; } } }

h3 {
  font-family: $font-bold;
  font-size: 16px;
  margin-bottom: 1rem; }

h4 {
  font-family: $font-bold;
  font-size: 13px;
  margin-bottom: 1rem; }

.header {
  background: $white;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  z-index: 10;
  .container {
      height: 76px;
      overflow: hidden; }
  .icon-logout-2 {
    margin-right: .5rem; }

  .menu {
    flex-grow: 1;
    padding-left: 2rem;
    a {
      display: block;
      margin-bottom: 1rem;
      font-family: $font-bold;
      color: $black;
      text-transform: uppercase;
      &:last-child {
        margin-bottom: 0; }
      &.active,
      &:hover,
      &:focus {
        color: $dark-red;
        text-decoration: none; } } } }


.footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: $black;
  text-align: center;
  a {
    font-family: $font-bold;
    color: $white;
    text-decoration: underline;
    margin: 0 .2rem; } }

p {
  color: $darker-grey;
  font-family: $font-regular;
  font-size: 15px;
  margin-bottom: 10px;
  line-height: 20px; }

.text,
p {
  em {
    font-style: italic; }
  strong {
    font-family: $font-bold; } }

.nav-tabs-2-items {
  border: 0;
  display: flex;
  font-family: $font-bold;
  background: $white;
  border-radius: 3px;
  box-shadow: 0 0 4px rgba(0,0,0,0.2);
  width: 380px;
  max-width: 100%;
  .nav-item {
    margin-bottom: 0;
    width: 50%;
    display: flex;
    &:first-child .nav-link {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px; }
    &:last-child .nav-link {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px; } }
  .nav-link {
    border: 0;
    border-radius: 0;
    color: $dark-grey;
    padding: 1rem 2rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    &.active {
      background: $dark-grey;
      color: $white;
      &::after {
        content: '';
        position: absolute;
        display: block;
        border: solid transparent;
        height: 0;
        width: 0;
        pointer-events: none;
        top: 100%;
        left: 50%;
        border-top-color: $dark-grey;
        border-width: 10px;
        transform: translateX(-50%); } } } }

.table-align-middle {
  td {
    vertical-align: middle; } }

.accordion {
  > .card {
    overflow: initial; }
  .card {
    margin-bottom: 1rem;
    border: 0;
    border-radius: 0;
    color: $dark-red;
    .card-header {
      background: transparent;
      border-radius: 0;
      margin: 0;
      border: 0;
      padding: 0;
      a {
        padding: .75rem 2rem .75rem 1rem;
        text-decoration: none;
        color: $dark-red;
        position: relative;
        &::before {
          content: '\e91f';
          position: absolute;
          top: 50%;
          right: 1rem;
          font-family: 'icomoon-nissan-you-plus';
          transform: translateY(-50%) rotate(-90deg); }
        &.collapsed {
          &::before {
            transform: translateY(-50%) rotate(90deg); } }
        &.with-icon {
          padding-left: 3rem;
          [class^="icon-"] {
            position: absolute;
            left: 1rem;
            top: 50%;
            transform: translateY(-50%); } } } }
    .card-body {
      color: $dark-grey;
      box-shadow: 0 3px 6px rgba(0,0,0,0.16); } } }

.bar-step {
  background: $darker-grey;
  display: flex;
  justify-content: center;
  padding: 0 2rem;
  margin-top: 20px;
  a {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 33.333%;
    justify-content: center;
    align-items: center;
    color: rgba(255,255,255,0.5);
    text-align: center;
    padding: 1rem 0 .5rem;
    border-bottom: .5rem solid transparent;
    margin: 0 3rem 0 0;
    text-decoration: none;
    &.active {
      color: $white;
      border-color: $dark-red; }
    &.checked {
      color: $white;
      &::after {
        content: '\e91c';
        font-family: 'icomoon-nissan-you-plus';
        font-size: 12px;
        color: $green;
        position: absolute;
        top: .5rem;
        left: 55%;
        margin-left: 1.2rem; } }

    &::before {
      content: '\e91f';
      font-family: 'icomoon-nissan-you-plus';
      color: rgba(255,255,255,0.5);
      font-size: 16px;
      position: absolute;
      right: -2rem;
      top: calc(50% + .25rem);
      transform: translateY(-50%); }
    &:first-child {}

    &:last-child {
      margin-right: 0;
      &::before {
        content: none; } }

    .icon {
      font-size: 26px;
      height: 36px;
      padding-top: 5px; }
    .icon-dealer-favorite {
      font-size: 36px;
      padding-top: 0; }

    .text {
      padding-top: .5rem; } }

  a.button {
    padding-top: .5rem;
    line-height: 1.1;
    &::before {
      top: 50%; } } }

.show-more {
  max-height: 58px;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  &::after {
    content: '';
    display: block;
    background: linear-gradient(to bottom, transparent, $background);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
  &.open {
    max-height: 300px;
    &::after {
      content: none; } } }


.footer,
.btn-show-more,
.show-more {
  font-size: 13px;
  position: relative;
  z-index: 1; }

.link-nav,
.btn-show-more {
  color: $dark-grey;
  cursor: pointer;
  text-decoration: underline;
  strong {
    font-family: $font-bold; } }

.btn-show-more {
  display: inline-block;
  &:after {
    content: "v";
    display: block;
    height: 10px;
    position: absolute;
    right: -20px;
    top: 0;
    width: 10px; }
  &.open {
    &:after {
      top: 10px;
      transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -webkit-transform: rotate(180deg); } } }



.link-back {
  position: relative;
  display: inline-block;
  margin-left: 1rem;
  &::before {
    content: '\e91f';
    font-family: 'icomoon-nissan-you-plus';
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: -1.2rem;
    transform: translateY(-50%) rotate(180deg); } }

.list-simple {
  list-style: disc inside;
  li {
    margin-bottom: .5rem; } }

.geocoder {
  input {
    width: 100%;
    border: none;
    padding-top: 4px;
    outline: none; }
  .react-geocoder-results {
    background: white;
    opacity: 1;
    padding: 10px;
    margin-top: 12px;
    margin-left: -10px;
    z-index: 10;
    position: absolute; } }

.list-adresses {
  li {
    position: relative;
    border-bottom: 1px solid $light-grey;
    padding: 0 2rem .5rem 4rem;
    margin-bottom: 1rem;
    //&:last-child
    //  border-bottom: 0
    &::after {
      content: '\e91f';
      font-family: 'icomoon-nissan-you-plus';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: .75rem; } }
  .icon {
    position: absolute;
    color: $dark-red;
    top: .5rem;
    left: 2rem;
    font-size: 30px;
    transform: translateX(-50%); }
  .distance {
    position: absolute;
    width: 4rem;
    text-align: center;
    top: 3rem;
    left: 0;
    color: $dark-red; }
  &.no-link {
    li {
      padding-right: 0;
      flex-basis: auto; }
    li::after {
      display: none; } }
  h3 {
    margin-bottom: 5px; }
  p {
    color: $grey;
    margin-bottom: 0; } }

.list-link-picto {
  li {
    position: relative;
    padding: 0 2rem .5rem 4rem;
    border-bottom: 1px solid $light-grey;
    &:last-child {
      border-bottom: 0; }
    a {
      display: block;
      color: $darker-grey;
      &.current {
        color: $grey; }
      &:focus,
      &:hover {
        text-decoration: none; }
      &::after {
        color: $dark-red;
        content: '\e91f';
        font-family: 'icomoon-nissan-you-plus';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: .75rem; } }
    [href^="tel"] {
      &::after {
        display: none; } } }
  .icon {
    position: absolute;
    top: 50%;
    left: 2rem;
    font-size: 30px;
    transform: translateX(-50%) translateY(-50%); }
  .icon-car-new {
    font-size: 20px;
    color: $dark-red; } }


.icon-profile-new,
.icon-car-del,
.icon-car-new {
  position: relative;
  &::after {
    content: '+';
    position: absolute;
    top: 0;
    right: 0;
    font-size: .7em;
    line-height: 1em;
    transform: translateY(-100%); } }

.icon-car-del::after {
  content: '-';
  font-size: 1.2em;
  top: 3px; }

.icon-profile-new::after {
  top: 10px;
  right: -8px;
  font-weight: bold; }

.icon-win {
  background: $lighter-grey;
  font-size: 126px;
  padding: 20px;
  border-radius: 50%;
  display: inline-block;
  position: relative; }


.icon-win-text {
  background: 0;
  border-radius: 0;
  font-size: 60px;
  padding: 0;
  .text {
    display: block;
    font-family: $font-regular;
    font-size: 12px;
    color: $white;
    text-align: center;
    padding: 3px 5px;
    position: relative;
    border-radius: 3px;
    margin-top: -10px; } }

.icon-with-circle {
  border: 2px solid $darker-grey;
  border-radius: 50%;
  padding: .5rem; }

.picto-link {
  cursor: pointer;
  position: relative;
  padding-right: 1rem;
  &::after {
    color: $dark-red;
    content: '\e91f';
    font-family: 'icomoon-nissan-you-plus';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: .25rem; } }

.without-arrow {
  .picto-link::after,
  .list-link-picto li a::after {
    display: none; } }

.tooltip-custom {
  border: 1px solid $grey-border;
  padding: 1rem;
  position: relative;
  border-radius: .5rem;
  background: $white;
  .close {
    color: $dark-red;
    cursor: pointer;
    position: absolute;
    font-size: 30px;
    line-height: 15px;
    top: 1rem;
    right: 1rem;
    transform: rotate(45deg);
    opacity: initial; }
  h3 {
    padding-right: 2rem; } }

.tooltip-custom-event {
  border: 1px solid $grey-border;
  padding: 1rem;
  position: relative;
  border-radius: .5rem;
  background: $dark-red;
  .close {
    color: $white;
    cursor: pointer;
    position: absolute;
    font-size: 30px;
    line-height: 15px;
    top: 1rem;
    right: 1rem;
    transform: rotate(45deg);
    opacity: initial; } }

.dropdown-toggle {
  display: inline-block;
  padding-right: 1.5rem;
  &::after {
    color: $dark-red;
    content: '\e91f';
    font-family: 'icomoon-nissan-you-plus';
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    right: .25rem;
    margin: 0;
    border: 0; } }

.btn-contact {
  position: fixed;
  background: $white;
  border: 2px solid $light-grey-border;
  color: $dark-red;
  font-size: 34px;
  padding: .75rem;
  border-radius: 50%;
  bottom: 2rem;
  right: 2rem;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
  z-index: 105;
  height: 75px;
  width: 75px;
  text-align: center;
  &:hover,
  &:focus {
    text-decoration: none;
    color: $darker-grey;
    border-color: $dark-red; } }

.nissan-card {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid $light-grey-border;
  img {
    float: left; }
  h2,
  p {
    white-space: nowrap;
    margin: 0; }
  h2 {
    padding-top: 3px; }
  p {
    color: $grey; } }

.header-vehicle {
  background: $dark-grey;
  color: $white;
  padding: .5rem 1.5rem;
  .doc {
    padding: 1rem 0 .5rem 0;
    justify-content: center;
    border-top: 1px solid $white;
    margin-top: .5rem;
    i {
      font-family: icon-Nissan-Global;
      font-size: 28px;
      font-weight: normal; } } }

.item-documentation {
  border: 1px solid $light-grey-border;
  border-bottom: 3px solid $dark-red;
  position: relative;
  width: 100%;
  /*&::after
    content: '\e91f'
    font-family: icomoon-nissan-you-plus
    position: absolute
    top: 50%
    right: .5rem
    transform: translateY(-50%)*/
  .download {
    border: 1px solid $light-grey-border;
    color: $grey-border;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem;
    text-decoration: none;
    .icon-download {} } }

.contracts-container {
  border: 1px solid $light-grey-border;
  .item {
    padding: 1rem;
    position: relative;
    border-bottom: 1px solid $light-grey-border;
    &:last-child {
      border-bottom: 0; }
    .status {
      font-family: $font-bold;
      margin: 0; }
    .collapse,
    .collapsing {
      font-size: 13px;
      overflow: hidden;
      padding: .8rem 0 0 0; }
    .icon-download {
      padding: .5rem;
      color: $grey-border;
      border: 1px solid $grey-border;
      transform: translateY(-.7rem); }
    .btn-collapse {
      position: absolute;
      top: 1rem;
      right: 1rem;
      display: inline-block;
      border: 2px solid $dark-red;
      border-radius: 50%;
      font-size: 22px;
      width: 24px;
      height: 24px;
      line-height: 18px;
      text-align: center;
      text-decoration: none;
      &.collapsed {
        &::before {
          content: '+'; }
        span {
          display: none; } } } } }


.modal-content {
  border: 0; }

.modal-header {
  background: $darker-grey;
  color: $white;
  padding: .5rem 1rem;
  .close {
    background: $white;
    opacity: 1;
    font-size: 12px;
    padding: .5rem;
    border-radius: 50%;
    margin: 0; } }

.modal-footer {
  padding-top: 0;
  border: 0;
  flex-wrap: wrap;
  justify-content: center;

  .button {
    max-width: 360px;
    width: 48%;
    min-width: 230px; } }

.about-offer-bloc {
  display: inline-block;
  background: $light-grey;
  color: $dark-red;
  border-left: 7px solid $dark-red;
  padding-left: 1rem;
  padding-right: 1rem;
  .icon {
    margin: 0 1rem 0 0;
    font-size: 20px;
    color: $dark-red; }
  .get-quote {
    border-bottom: 1px solid $light-grey-border;
    a {
      padding: 0 .8rem;
      color: $dark-grey;
      position: relative;
      &:hover,
      &:focus {
        text-decoration: none; }
      &:after {
        color: $dark-red;
        content: '\e91f';
        font-family: 'icomoon-nissan-you-plus';
        position: absolute;
        top: 7px;
        right: 0; } } } }

.text-decoration-underline {
  text-decoration: underline; }

.info-birthday {
  position: relative;
  display: inline-block;
  &:focus {

    &::after {
      content: attr(data-info);
      display: block;
      color: $black;
      text-decoration: none;
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      background: $light-grey;
      padding: 1rem;
      margin: 0 0 1rem 0; } }


  .links-contact {
    a {
      display: flex;
      flex-grow: 1;
      text-transform: uppercase;
      font-family: $font-bold;
      align-items: center;
      justify-content: center;
      padding: 0 .8rem;
      &:first-child {
        border-right: 1px solid $light-grey-border; }
      &:hover,
      &:focus {
        text-decoration: none; }
      &:last-child {
        border-right: 0; } } }

  .links-contact-go {
    a {
      display: flex;
      flex-grow: 1;
      text-transform: uppercase;
      font-family: $font-bold;
      align-items: center;
      justify-content: left;
      padding: 1rem 1rem;
      border-bottom: 1px solid $light-grey-border;
      &:hover,
      &:focus {
        text-decoration: none; }
      &:last-child {
        border-right: 0; } } }

  .links-contact-call {
    a {
      display: flex;
      flex-grow: 1;
      text-transform: uppercase;
      font-family: $font-bold;
      align-items: center;
      justify-content: left;
      padding: 1rem 1rem;
      &:hover,
      &:focus {
        text-decoration: none; }
      &:last-child {
        border-right: 0; } } } }

.tabulator .tabulator-header {
  .tabulator-col.tabulator-sortable[aria-sort="descending"],
  .tabulator-col.tabulator-sortable[aria-sort="ascending"] {
    .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
      border-bottom-color: $dark-red; } } }

.tabulator .tabulator-header {
  .tabulator-col.tabulator-sortable[aria-sort="descending"] {
    .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
      transform: rotate(180deg); } } }

@media (min-width: $bp-xs) {
  .licence-plate input, .button {
    max-width: 300px; }
  .button {
    &.back {
      max-width: 320px;

      &::before {
        left: 0.5rem; } } }

  .mobile-container {
    padding: 15px 15px 0 15px; } }

@media (min-width: $bp-sm) {
  .link-back {
    margin-left: 0; } }


@media (min-width: $bp-md) {

  .header .menu a {
    display: inline-block;
    margin-bottom: 0; }

  .list-adresses {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      flex: 0 1 46%;
      margin-bottom: 2rem; } }

  .border-right-md {
    border-right: 1px solid $light-grey; }

  .no-border-bottom-md {
    border-bottom: 0 !important; }

  .nissan-card {
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    padding-right: 80px;
    padding-bottom: 0;
    border: 0;
    margin: 0; }

  .header-vehicle {
    .doc {
      padding: 1rem 0 1rem 1.5rem;
      border-left: 1px solid $white;
      border-top: 0;
      margin-top: 0; } }
  .button {
    &.back {
      &::before {
        left: 1rem; } } }

  .mobile-container {
    padding: 0; }

  .modal-footer {
    justify-content: center;
    .button {
      margin-left: 0;
      margin-right: 0; } } }

@media (min-width: $bp-lg) {
  .modal-footer {
    justify-content: space-between; }

  .button {
    max-width: 340px; } }

.line-height {
  line-height: 22px; }

.board-info-title {
  margin-top: 40px;
  .icon-check {
    color: $green; } }
.grey-title {
  color: $grey-border!important; }

.board-end-info {
  .icon-check {
    color: $green;
    font-size: 53px;
    margin-bottom: 20px; } }

.board-info-footer {
  color: black!important;
  text-decoration: underline;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer; }


.logo-width {
  width: 90px!important; }

.card-stats {
  .card-body {
    padding: .5rem 1rem; }
  .card-title {
    margin-bottom: 0;
    flex-grow: 1;
    text-align: center;
    font-size: 16px;
    padding-left: 1rem;
    strong {
      font-size: 30px; }
    .last-mentions {
      font-size: 12px;
      color: $grey-border; } } }

.cards-stats {
  .card {
    border-radius: 0;
    border-right-width: 0;
    &:first-child {
      border-radius: .25rem 0 0 .25rem; }
    &:last-child {
      border-right-width: 1px;
      border-radius: 0 .25rem .25rem 0; } } }

.ant-table {
  font-family: $font-regular !important;
  font-size: 13px !important;
  line-height: 1.2 !important;
  thead>tr:first-child >*:first-child,
  thead>tr:first-child >*:last-child {
    border-radius: 0 !important; }
  th[data-after-title]::after {
    content: attr(data-after-title);
    display: block;
    font-size: 10px;
    color: $grey;
    font-family: $font-regular;
    font-weight: normal; }

  .ant-table-thead >tr>th {
    background: 0 !important; } }

.table-custom {

  td,
  th {
    .legend {
      color: grey; }
    &.firstColName {
      max-width: 200px; } }
  th {
    .legend {
      font-size: 10px; } }
  td {
    line-height: 1.4;
    .legend {
      font-size: 11px; }
    .ellipsis {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%; }
    .inactive {
      color: $dark-red;
      font-style: italic;
      //&::before
 } } }      //  content: '● '


//body > div[style]:last-of-type
//  //display: none !important;
//  //visibility: hidden !important
//  //opacity: 0 !important
//  //padding: 0 !important
//  //height: 1px
//  //width: 1px
//  //overflow: hidden !important

#search-form {
  position: relative;
  z-index: 200; }
