$input-border: #d8d8d8;
$grey: #767676;
$dark-grey: #4C4C4C;
$icon-grey: #646464;
$darker-grey: #343334;
$grey-border: #707070;
$light-grey-border: #C1C1C1;
$light-grey: #E9E9E9;
$lighter-grey: #FAFAFA;
$light-grey-text: #b5b0b0;
$dark-red: #C0092D;
$white: #FFFFFF;
$black: #000000;
$green: #1EA50D;
$yellow-licence: #F9C032;
$blue-licence: #3996E6;
$background: #e3e3e3;

$cup-bronze: #C25E35;
$cup-argent: #9A9A9A;
$cup-or: #B39443;
$cup-platine: #4E5C66;

$blue-active: #0081FF;

$bp-xs: 320px;
$bp-sm: 576px;
$bp-md: 768px;
$bp-lg: 992px;
$bp-xl: 1200px;

$font-regular: "Nissan Regular", Verdana, Arial, sans-serif;
$font-bold: "Nissan Bold", Verdana, Arial, sans-serif;

// Header Footer
$global-nav: #eee;
$nav-link: #343434;
$header-dark-red: #c3002f;
$vehicle-border: #8a8a8a;
$mobile-border: #D2D2D2;
$subnav-border: #e3e2e2;

