@font-face {
  font-family: "icon-Nissan-Global";
  src: url("../fonts/icons/Nissan-Global.woff"); }

@font-face {
  font-family: "Nissan Bold";
  src: url('../fonts/nissan_brand_bold-webfont.eot');
  src: url('../fonts/nissan_brand_bold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/NissanBrandW15-Bold.woff2') format('woff2'), url('../fonts/nissan_brand_bold-webfont.woff') format('woff'), url('../fonts/nissan_brand_bold-webfont.ttf') format('truetype'), url('../fonts/nissan_brand_bold-webfont.svg#nissan_brandbold') format('svg'); }



@font-face {
  font-family: "Nissan Regular";
  src: url('../fonts/nissan_brand_regular-webfont.eot');
  src: url('../fonts/nissan_brand_regular-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/NissanBrandW01-Regular.woff2') format('woff2'), url('../fonts/nissan_brand_regular-webfont.woff') format('woff'), url('../fonts/nissan_brand_regular-webfont.ttf') format('truetype'), url('../fonts/nissan_brand_regular-webfont.svg#nissan_brandregular') format('svg'); }



// Custom icons

@font-face {
  font-family: 'icomoon-nissan-you-plus';
  src: url('../fonts/icomoon-nissan-you-plus.ttf?hunmpi') format('truetype'), url('../fonts/icomoon-nissan-you-plus.woff?hunmpi') format('woff'), url('../fonts/icomoon-nissan-you-plus.svg?hunmpi#icomoon-nissan-you-plus') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block; }

@font-face {
  font-family: 'icomoon-nissan-club';
  src: url('../fonts/icomoon-nissan-club.ttf?7sqoxd') format('truetype'), url('../fonts/icomoon-nissan-club.woff?7sqoxd') format('woff'), url('../fonts/icomoon-nissan-club.svg?7sqoxd#icomoon-nissan-club') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon-nissan-you-plus' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="icon-2-"], [class*=" icon-2-"] {
  font-family: 'icomoon-nissan-club' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-circle {
  border: 2px solid black;
  border-radius: 50%;
  width: 24px;
  display: inline-block;
  text-align: center;
  line-height: 15px;
  height: 24px; }


// From font custom icomoon

.icon-facebook:before {
  content: "\e933"; }

.icon-ticket-f:before {
  content: "\e934"; }

.icon-profile:before {
  content: "\e935"; }

.icon-cup:before {
  content: "\e936"; }

.icon-notification:before {
  content: "\e937"; }

.icon-logout-2:before {
  content: "\e938"; }

.icon-warranty:before {
  content: "\e931"; }

.icon-contact:before {
  content: "\e932"; }

.icon-time:before {
  content: "\e92e"; }

.icon-settings:before {
  content: "\e92f"; }

.icon-pencil:before {
  content: "\e930"; }

.icon-pin-marker:before {
  content: "\e923"; }

.icon-ticket:before {
  content: "\e924"; }

.icon-phone:before {
  content: "\e925"; }

.icon-calcul-contract:before {
  content: "\e926"; }

.icon-truck-assist:before {
  content: "\e927"; }

.icon-card:before {
  content: "\e928"; }

.icon-contact-end:before {
  content: "\e929"; }

.icon-inspection:before {
  content: "\e92a"; }

.icon-tire:before {
  content: "\e92b"; }

.icon-questions:before {
  content: "\e92c"; }

.icon-download:before {
  content: "\e92d"; }

.icon-chevron:before {
  content: "\e91f"; }

.icon-confirm-email:before {
  content: "\e91d"; }

.icon-win {
  .path1:before {
    content: "\e903";
    color: rgb(242, 242, 242); }
  &.icon-win-no-bg {
    .path1:before {
      color: transparent; } }
  .path2:before {
    content: "\e906";
    margin-left: -1em;
    color: rgb(143, 69, 38); }
  .path3:before {
    content: "\e907";
    margin-left: -1em;
    color: rgb(194, 94, 53); }
  .text {
    background-color: rgb(194, 94, 53); } }

.icon-win.silver {
  .path2:before {
    color: #656565; }
  .path3:before {
    color: #9A9A9A; }
  .text {
    background-color: #9A9A9A; } }

.icon-win.gold {
  .path2:before {
    color: #7D5B1A; }
  .path3:before {
    color: #B39443; }
  .text {
    background-color: #B39443; } }

.icon-win.platinum {
  .path2:before {
    color: #354048; }
  .path3:before {
    color: #4E5C66; }
  .text {
    background-color: #4E5C66; } }

.icon-search-2:before {
  content: "\e908"; }

.icon-marker:before {
  content: "\e922"; }

.icon-car:before {
  content: "\e90b"; }

.icon-star:before {
  content: "\e90d"; }

.icon-dealer-favorite:before {
  content: "\e90f"; }

.icon-dealer:before {
  content: "\e911"; }

.icon-help:before {
  content: "\e912"; }

.icon-error:before {
  content: "\e913"; }

.icon-globe:before {
  content: "\e915"; }

.icon-calendar-maintenance:before {
  content: "\e916"; }

.icon-reward:before {
  content: "\e917"; }

.icon-offers:before {
  content: "\e918"; }

.icon-inbox:before {
  content: "\e919"; }

.icon-magazine:before {
  content: "\e91a"; }

.icon-calendar:before {
  content: "\e91b"; }

.icon-check:before {
  content: "\e91c"; }

.icon-eye:before {
  content: "\e91e"; }

.icon-close:before {
  content: "\e900"; }

.icon-search:before {
  content: "\e901"; }

.icon-logout:before {
  content: "\e902"; }

.icon-contracts:before {
  content: "\e904"; }

.icon-maintenance:before {
  content: "\e905"; }

.icon-recall:before {
  content: "\e909"; }

.icon-logo-nissan:before {
  content: "\e90a"; }

.icon-extension-garanti:before {
  content: "\e921"; }

.icon-atelier:before {
  content: "\e90c"; }

.icon-assistance:before {
  content: "\e90e"; }

.icon-controle-technique:before {
  content: "\e920"; }

.icon-contract-check:before {
  content: "\e910"; }

.icon-characteristics:before {
  content: "\e914"; }


// icomoon V2

.icon-2-delete:before {
  content: "\e900"; }

.icon-2-card-check:before {
  content: "\e901"; }

.icon-2-card-cvc:before {
  content: "\e902"; }

.icon-2-user:before {
  content: "\e903"; }

.icon-2-download:before {
  content: "\f019"; }

.icon-2-check-bold:before {
  content: "\f00e"; }

.icon-2-close-bold:before {
  content: "\f00f"; }

.icon-2-long-arrow-down:before {
  content: "\f177"; }

.icon-2-long-arrow-up:before {
  content: "\f178"; }

.icon-2-lock:before {
  content: "\f023"; }

.icon-2-sign-in:before {
  content: "\f090"; }

.icon-2-rotate-left:before {
  content: "\f0e2"; }

.icon-2-undo:before {
  content: "\f0e2"; }

.icon-2-user-bold:before {
  content: "\f007"; }

.icon-2-envelope:before {
  content: "\f0e0"; }

.icon-2-address-card:before {
  content: "\f2bb"; }

.icon-2-vcard:before {
  content: "\f2bb"; }

