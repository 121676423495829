@import 'variables';

.home {
  main {
    position: relative; }
  .home-cover {
    background: {
      color: $white;
      position: center top;
      repeat: no-repeat;
      size: cover; }
    display: inline-block;
    float: left; }
  .white-container {
    display: inline-block;
    margin-bottom: 0;
    position: relative;
    z-index: 2; }
  .home-rewards {
    margin: {
      bottom: 30px;
      top: 30px; }
    overflow: auto;
    text-align: center;
    color: $icon-grey;
    &__item {
      font-family: $font-bold;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase; }
    &__icon {
      font-size: 32px;
      display: inline-block;
      margin-bottom: 5px; } }

  .footer {
    padding: 1rem 0;
    background: white; }

  .logo-desktop {
    max-width: 100%;
    height: auto; }

  .lang-bar {
    text-align: center;
    color: $dark-red;
    span {
      color: $darker-grey;
      cursor: pointer;
      &:hover,
      &:focus,
      &.active {
        display: inline-block;
        font-family: $font-bold;
        text-decoration: none;
        padding-bottom: 5px;
        border-bottom: 4px solid $dark-red; } } } }

.form-control:disabled {
  background: #999; }

@media (min-width: $bp-xs) {
  .home {} }

@media (min-width: $bp-md) {
  .home {
    .white-container {
      width: 530px; } } }
