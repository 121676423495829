@import 'variables';

label {
  color: $darker-grey;
  font: {
    family: Nissan Bold;
    size: 15px; }
  margin-bottom: 8px;
  text-transform: uppercase;
  &.required {
    &:after {
      content: "*";
      color: $dark-red; } }
  &.no-transform {
    text-transform: none; } }

.pt-label {
  padding-top: 32px; }

.react-select__control,
.form-control {
  background-color: #FFF;
  border: 1px solid $input-border;
  border-radius: 3px;
  box-shadow: 0 3px 6px rgba(0,0,0, 0.16);
  -moz-box-shadow: 0 3px 6px rgba(0,0,0, 0.16);
  -webkit-box-shadow: 0 3px 6px rgba(0,0,0, 0.16);
  height: 45px;
  margin-bottom: 10px;
  color: $dark-grey;
  &:focus {
    border-color: $input-border;
    box-shadow: 0 3px 6px rgba(0,0,0, 0.16);
    -moz-box-shadow: 0 3px 6px rgba(0,0,0, 0.16);
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0, 0.16);
    color: $darker-grey; }
  &.error {
    border-color: $dark-red; }
  &:disabled {
    background: $white;
    border-color: transparent; } }

.color-error {
  color: $dark-red; }

.form-group {
  margin-bottom: 15px;
  .icon-check {
    color: $green;
    font-size: 12px; }
  .icon-close {
    font-size: 12px; }
  .btn-group {
    border-radius: 3px;
    box-shadow: 0 3px 6px rgba(0,0,0, 0.16);
    -moz-box-shadow: 0 3px 6px rgba(0,0,0, 0.16);
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0, 0.16);
    height: 45px;
    width: 100%;
    .btn {
      border-color: $input-border;
      background-color: $white;
      color: $black;
      width: 50%;
      &.selected {
        background-color: $dark-grey;
        color: $white; }
      &:focus, &:active, &:focus:active {
        box-shadow: none; }
      &:active {
        background-color: $darker-grey; } } }


  .react-datepicker-wrapper {
    width: 100%;
    input {
      background: url('../images/calendar.png') 10px center / 22px no-repeat $white;
      cursor: pointer;
      padding-left: 40px;
      width: 100%; } } }

.form-check-label {
  background: url('../images/checkbox.png') left 2px / 35px no-repeat;
  cursor: pointer;
  font-family: $font-regular;
  line-height: 20px;
  padding-left: 40px;
  text-transform: initial; }
.form-check-input {
  display: none;
  &:checked {
    & + .form-check-label {
      background-image: url('../images/checkbox-active.png'); } } }


.password-wrapper {
  position: relative;
  &.error input {
    border-color: $dark-red; }
  .toggle-pwd {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 15px; } }

.form-text {
  font-family: $font-bold;
  &.form-validate-ok {
    color: $green; }
  &.form-validate-error {
    color: $dark-red;
    padding-left: 1px;
    span {
      margin-right: 13px; } }
  .icon-close {
    margin-right: 13px; }

  span.birth-info {
    color: $dark-red;
    cursor: pointer;
    font: {
      family: $font-regular;
      size: 13px; }
    text-decoration: underline; }

  a {
    color: $grey-border;
    font: {
      family: $font-regular;
      size: 13px; }
    text-decoration: underline;
    &:hover, &:active, &:focus {
      color: $grey-border;
      text-decoration: underline; } } }

.info-bulle {
  background-color: $light-grey;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  position: absolute;
  max-height: 200px;
  overflow: scroll;
  font-size: 13px;
  padding: 10px;
  z-index: 100;
  top: 38px;

  &::before {
    background-color: $light-grey;
    content: "";
    display: block;
    height: 15px;
    width: 15px;
    position: absolute;
    top: -7px;
    left: 53px;
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg); } }


.required-field {
  color: $dark-red;
  font: {
    family: $font-regular;
    size: 13px; }
  margin: 10px 0; }

.licence-plate {
  position: relative;
  #B {
    background: $white;
    color: red;
    border: 0;
    padding-left: 4rem;
    font-family: $font-bold;
    &:focus {
      background: $white;
      color: red; }
    &::placeholder {
      color: red; } }
  #LU, #NL, #nl-NL {
    background: $yellow-licence;
    color: $black;
    border: 0;
    padding-left: 4rem;
    font-family: $font-bold;
    &:focus {
      background: $yellow-licence;
      color: $black; } }
  .licence__prefix {
    content: 'NL';
    color: $white;
    font-family: $font-bold;
    display: block;
    background: $blue-licence;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 0 1rem;
    line-height: 45px;
    border-radius: 3px 0 0 3px; } }

.date .react-datepicker-wrapper {
    display: block;
    .react-datepicker__close-icon {
        bottom: 10px;
        height: auto; }

    .react-datepicker__close-icon::after {
        background-color: transparent;
        border: 0;
        color: #343334;
        font-size: 20px; } }

.input-units {
  position: relative;
  &::after {
    content: attr(data-units);
    position: absolute;
    font-family: $font-bold;
    top: 0;
    bottom: 0;
    right: 1rem;
    line-height: 45px; }
  input {
    padding-right: 4rem; }
  &.required {
    &::before {
      content: '\e91c';
      font-family: icomoon-nissan-you-plus;
      font-size: 14px;
      color: $green;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 6rem; } } }


input[type=checkbox],
input[type=radio] {
  &.form-control {
    box-shadow: none;
    height: auto;
    width: auto;
    margin: 0; } }

.radio-date {
  position: relative;
  input {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1rem;
    opacity: 0; }
  input:checked + label::after {
    content: '\e91c';
    font-family: icomoon-nissan-you-plus;
    color: $green;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1rem; }
  .button {
    padding-left: 2.5rem;
    padding-right: 2.5rem !important;
    font-size: 17px;
    max-width: none; }
  label {
    text-transform: none; }
  .react-datepicker-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; } }

.input-with-icon {
  position: relative;
  input {
    text-transform: none;
    padding-left: 2.25rem; }
  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: .75rem;
    font-size: 20px;
    color: $grey; }
  input:focus + .icon {
    color: $darker-grey; } }

.icon-right {
  input {
    padding-left: .75rem;
    padding-right: 3rem; }
  .icon {
    left: auto;
    right: 1rem; } }

.radio-custom,
.checkbox-custom {
  position: relative;
  margin-bottom: 1rem;
  input {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: .5rem;
    opacity: 0; }
  input + label::before {
    content: '';
    display: block;
    width: 2rem;
    height: 2rem;
    border: 1px solid $input-border;
    box-shadow: 0 2px .5rem rgba(0,0,0,0.2);
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 3px;
    background: $white; }
  input:checked + label::after {
    content: '\e91c';
    display: block;
    font-family: 'icomoon-nissan-you-plus';
    width: 2rem;
    height: 2rem;
    font-size: 12px;
    color: $dark-red;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
    border: 2px solid $dark-red;
    text-align: center;
    line-height: 28px; }

  label {
    cursor: pointer;
    font-weight: normal;
    font-family: 'Nissan Regular';
    margin-bottom: 0;
    display: block;
    padding-left: 3rem;
    line-height: 2rem;
    text-transform: none;
    &.multiline {
      line-height: 1.2rem; } } }

.radio-custom {
  input + label::before {
    border-radius: 50%; }
  input:checked + label::after {
    border-radius: 50%; } }

.checkbox-custom {
  .form-check-label {
    background: none !important; } }

.phone-number {
  position: relative;
  input {
    padding-left: 4rem; }
  &::after {
    content: attr(data-indicatif);
    color: $dark-grey;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 0 0 0 1rem;
    line-height: 45px; } }

.select {
  position: relative;
  &::before {
    content: '\e91f';
    font-family: 'icomoon-nissan-you-plus';
    display: block;
    position: absolute;
    top: 50%;
    right: .7rem;
    transform: rotate(90deg) translateY(-50%);
    transform-origin: top center;
    pointer-events: none;
    font-size: 13px; }
  select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none; } }

.react-select-container {
  z-index: 1000; }

.react-select__dropdown-indicator {
  width: 32px;
  position: relative;
  &::before {
    content: '\e91f';
    font-family: 'icomoon-nissan-you-plus';
    display: block;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: rotate(90deg) translateY(-50%);
    transform-origin: top center;
    pointer-events: none;
    color: #212529; }
  svg {
    display: none; } }

.react-select__value-container--is-multi {
    padding-right: 0 !important;
    padding-left: 10px !important;
    height: 43px;
    overflow: auto !important; }

.react-select__multi-value {
    margin-bottom: 0 !important; }

.react-select__input-container {
    padding: 0 !important;
    margin-bottom: 0 !important; }

.react-select__clear-indicator {
    padding: 2px !important; }

textarea.form-control {
  text-transform: none; }

.react-select__placeholder {
  color: $dark-grey !important; }

.react-select__group {
    padding: 0 !important; }

.react-select__group-heading {
  font-size: .9rem !important;
  font-weight: bold !important;
  color: $dark-grey !important;
  background: $light-grey !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin: 0 !important; }

.react-select__menu-list {
  padding: 0 !important; }

.react-select__option--is-focused {
  background: lighten($dark-red, 50%) !important; }

@media (min-width: $bp-xs) {
  .info-bulle {
    top: 32px; } }

@media (min-width: $bp-md) {
  .info-bulle {
    top: 50px; } }
